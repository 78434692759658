<template>
    <v-card-text>
        <div class="form-box-title">{{$t(title)}}</div>

        <div v-for='(item, index) in items' :key='index' class="eds-info-box eds-status-fine" :class="GetBorderColor(item[5])">

            <div class="eds-ib-flex">

                <v-chip
                    class="person-label"
                    small
                    label
                >
                    <i class="fas fa-user"></i>
                    <div>{{item[8]}}</div>
                </v-chip>

                {{item[4]}}

            </div>

            <div class="eds-desc more-per-lab-wrap">

                <template v-if="isReject">
                    <span>{{$t("Причина")}}: {{item[9]}}</span>
                </template>

                <template v-else-if="isRework">
                    <span>{{$t("Причина")}}: {{item[9]}}</span>
                    <v-chip v-if="item[10]"
                        class="person-label file-label"
                        small
                        label
                    >
                        <i class="fas fa-file-alt"></i>
                        <div>{{item[11]}}</div>
                        <i class="fas fa-download" @click="onDownload(item[10], item[11])"></i>
                    </v-chip>
                </template>

                <template v-else>
                    <v-icon :color="GetFillColor(item[5])">
                        {{'fas fa-key'}}
                    </v-icon>

                    <span>
                        {{GetSignStatus(item[5])}}, {{$t("владелец_ЭЦП")}} ({{item[3]}}), {{$t("тип_ЭЦП")}} ({{GetEDSType(item[2])}}){{ isAttach ? `, ${$t("наименование_вложения")} ${GetAttachName(item[9])}` : ''}}
                    </span>
                </template>

            </div>

        </div>

    </v-card-text>
</template>

<script>
/*
    0 - id ЭЦП,
    1 - Тип подписи (0 - руководитель, 1 - канцелярия, 3 - заявитель, 4 - подписи вложений, 5 - простая, 6 - отказ, 7 - доработка),
    2 - Тип подписи по центру сертификации (0 - простая, 1 - УЦГО, 2 - НУЦ),
    3 - Фамилия Имя из DN,
    4 - Дата подписи,
    5 - дествительна ли подпись (0 - недействительна, 1 - действительна, -1 - неизвестно)
    6 - дествительн ли сертификат (0 - недействительна, 1 - действительна, -1 - неизвестно)
    7 - employeeid (для облачка, если понадобится),
    8 - ФИО по сотруднику
    9 - название файла (только для ЕУОЛ с портала),
    10 - id вложения,
    11 - название файла
*/
import { mapGetters } from 'vuex';
import { httpAPI } from "@/api/httpAPI"
export default {
    name: 'EDSPanel',
    props:
    {
        title: {
            type: String,
            default: null
        },
        items: [],
        isReject: {
            type: Boolean,
            default: false
        },
        isRework: {
            type: Boolean,
            default: false
        },
        isAttach: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed:{
        ...mapGetters('references', { edsTypes: 'GetEDSTypes', edsStatuses: 'GetEDSStatusesTypes' })
    },
    methods:
    {
        GetEDSType(source) {
            let status = parseInt(source);
            if (isNaN(status))
                status = 1;
                
            return this.edsTypes.find(x => x.id == status)?.Value;
        },
        GetSignStatus(source) {
            let status = parseInt(source);

            if (isNaN(status))
                status = 1;            

            return this.edsStatuses.find(x => x.id == status)?.Value;
        },
        GetBorderColor(source) {
            if (this.isReject)
                return "eds-status-border-bad";
            
            if (this.isRework)
                return "eds-status-border-warning";

            let status = parseInt(source);

            if (isNaN(status))
                status = -1;

            switch(status)
            {
                case 0:
                    return "eds-status-border-bad";
                case 1:
                    return "eds-status-border-fine";
                case 2:
                    return "eds-status-border-warning";
                default:
                    return "eds-status-border-default";    
            }
        },
        GetFillColor(source) {
            let status = parseInt(source);

            if (isNaN(status))
                status = -1;

            switch(status)
            {
                case 0:
                    return "#e55353";
                case 1:
                    return "#2eb85c";
                default:
                    return "#ccc";    
            }
        },
        GetAttachName(source) {
            return source.replace(".psign", "");
        },
        async onDownload(attachId, attachName) {
            // create URL
            let url = `api/download/attachment?id=${attachId}&name=${attachName}&mode=${process.env.NODE_ENV}&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}`;
            
            if (process.env.NODE_ENV === "production") {
                url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                const link = document.createElement('a');
                link.href = url;
                link.download = attachName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                let response = await httpAPI({ url, method: 'GET', responseType: 'blob' });

                if (response) {
                    const url = URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', attachName);
                    document.body.appendChild(link);
                    link.click();
                }
            }
        },
    }
}
</script>